import React, { Fragment } from "react"
import { Styled } from "theme-ui"

/**
 * Change the content to add your own bio
 */

export default () => (
  <Fragment>
    Welcome to the official <Styled.a href="https://www.carlostrinidad.me">Carlos Trinidad</Styled.a> blog.
    <br />
    This is where I share my w̶i̶s̶d̶o̶m humble knowledge. Continue at your own risk.
  </Fragment>
)
